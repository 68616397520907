import { useNavigate } from 'react-router-dom';
import { Menu, Group, Text, Box, Stack } from '@mantine/core';
import {
  IconUserCircle,
  IconLogout,
  IconBug,
  IconSmartHome,
  IconCertificate,
  IconDeviceLaptop
} from '@tabler/icons';
import { useActions } from '@hooks/redux/action';
import { ITokens, IUser } from '@/entities/admin-app/auth';
import { useLazyLogoutQuery } from '@/entities/admin-app/user/api';
import { useStyles } from './styles';
import { ROUTES } from '@/containers/routes/constants';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { UserAvatar } from '../user-avatar';
import { useAppSelector } from '@/hooks/redux/redux';
import { isAdmin, isSaaS } from '@/lib/utils/access';

interface Props {
  user: IUser;
}

export const UserMenu = ({ user }: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setUserInfo, setUserTokens, setSelectedAllUsers } = useActions();
  const [fetchLogout] = useLazyLogoutQuery();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const currentUser = useAppSelector((state) => state.userInfo.User);

  const handleLogout = async () => {
    setUserInfo({} as IUser);
    setUserTokens({} as ITokens);
    setSelectedAllUsers([]);
    navigate('/login');
    await fetchLogout();
  };

  const avatarSrc = useMemo(() => {
    return user?.HasAvatar
      ? `/api/v2/profile/getAvatar?id=${user.Id}${user.AvatarKey ? `&key=${user.AvatarKey}` : ''}`
      : '';
  }, [user.AvatarKey, user.HasAvatar]);

  return (
    <Menu
      position="bottom-end"
      shadow="sm"
      transitionProps={{ transition: 'pop-top-right', duration: 150 }}
      zIndex={10001}
      radius={8}
      classNames={{
        dropdown: classes.dropdown,
        item: classes.menuItem,
        itemLabel: classes.itemLabel,
        itemSection: classes.itemIcon
      }}
    >
      <Menu.Target>
        <UserAvatar data-cy="profileAvatar" user={user} src={avatarSrc} size={36} />
      </Menu.Target>
      <Menu.Dropdown miw={320} maw={400}>
        <Group p="8px 12px 0 24px" pt={6} gap={12}>
          <UserAvatar
            user={user}
            src={user?.HasAvatar ? `/api/v2/profile/getAvatar?id=${user.Id}` : null}
            size={50}
          />
          <Stack gap={0}>
            <Text fz={18} className={classes.truncate}>
              {user.Surname ?? '-'} {user.FirstName ?? '-'}
            </Text>
            <Text fz={14} c="dimmed" className={classes.truncate}>
              {user.Email}
            </Text>
          </Stack>
        </Group>
        <Menu.Divider m="12px 0" color="#DEE2E6" />
        <Box px="8px" pb="8px">
          <Menu.Item
            component="a"
            data-cy="homePageBtn"
            leftSection={<IconSmartHome stroke={1.2} size={24} />}
            px="sm"
            my="4px"
            href={ROUTES.dashboard.fullPath}
            target="_blank"
          >
            {t('home')}
          </Menu.Item>
          <Menu.Item
            component="a"
            data-cy="profileBtn"
            leftSection={<IconUserCircle stroke={1.2} size={24} />}
            px="sm"
            my="4px"
            href={`/profile/${user.Id}`}
            target="_blank"
          >
            {t('profil')}
          </Menu.Item>
          {isSaaS(Settings) && (
            <>
              {isAdmin(currentUser) && (
                <Menu.Item
                  component="a"
                  data-cy="paymentBtn"
                  leftSection={<IconCertificate stroke={1.2} size={24} />}
                  px="sm"
                  my="4px"
                  target="_blank"
                  href="https://payments.r7-space.ru"
                >
                  {t('tarif')}
                </Menu.Item>
              )}
              <Menu.Item
                component="a"
                data-cy="downloadBtn"
                leftSection={<IconDeviceLaptop stroke={1.2} size={24} />}
                px="sm"
                my="4px"
                target="_blank"
                href="https://preview.r7-space.ru"
              >
                {t('download')}
              </Menu.Item>
              <Menu.Item
                component="a"
                data-cy="feedbackBtn"
                leftSection={<IconBug stroke={1.2} size={24} />}
                px="sm"
                my="4px"
                target="_blank"
                href="https://r7-space.ru/feedback"
              >
                {t('reportError')}
              </Menu.Item>
            </>
          )}

          <Menu.Item
            component="a"
            data-cy="logoutBtn"
            onClick={handleLogout}
            leftSection={<IconLogout stroke={1.2} size={24} />}
            px="sm"
            my="4px"
          >
            {t('exit')}
          </Menu.Item>
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
};
